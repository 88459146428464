<template>
  <div class="registrationconfirmation">
    <div
      class="registrationconfirmation__logo"
      @click="gotToHomePage()"
    >
      <img
        src="./home-assets/img/logo/logo-white.svg"
        alt=""
      >
    </div>
    <div class="registrationconfirmation-content">
      <div class="registrationconfirmation-content__title">
        Thank you!
      </div>
      <div class="registrationconfirmation-content__text">
        We appreciate you very much and are excited to have you on board.
        We are working on getting your admin system ready, you should receive an email invite from us within the next few minutes.
      </div>
      <div class="registrationconfirmation-content__text_italic registrationconfirmation-content__text">
        Please check your email.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Registrationconfirmation',
  computed: {
    showStraightOffModalSignUp: {
      get() {
        return this.$store.getters.showStraightOffModalSignUp;
      },
      set(data) {
        this.$store.commit('setShowStraightOffModalSignUp', data);
      },
    },
  },
  mounted() {
    window.Intercom('trackEvent', 'Registration Confirmation of Paid Customer');
    const [preloaderWapper] = document.getElementsByClassName('preloader-wapper');
    if (preloaderWapper) {
      preloaderWapper.classList.add('loaded');
    }
    setTimeout(() => {
      this.gotToHomePage();
    }, 5000);
  },
  methods: {
    gotToHomePage() {
      if (this.showStraightOffModalSignUp) {
        window.location.href = 'https://prayerwall.app/';
      } else {
        this.$router.push({ name: 'index' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import './assets/sass/utils/variables';

.registrationconfirmation {
  margin-top: -24px;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 30px;
  }

  &__logo {
    margin-bottom: 20.5vh;
    display: inline-block;

    @media (max-width: 767px) {
      margin-bottom: 18vh;
    }

  }

}

.registrationconfirmation-content {
  padding: 15px;
  width: 80%;
  max-width: 810px;
  margin: 0 auto;

  &__title {
    margin-bottom: 34px;
    font: 56px $font-global-bold;
    color: #191927;

    @media (max-width: 767px) {
      font-size: 38px;
    }

  }

  &__text {
    margin-bottom: 33px;
    font: 18px / 1.5 $font-global;
    color: #909090;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    &_italic {
      font-style: italic;
    }

  }

}
</style>
<!-- Created by zas on 23.04.2021  -->
